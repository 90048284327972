.container {
    height: 50px;
    padding: 0px 25px;
    color: #68707D;
    background: #fff;
    border: none;
    border-radius: 6px;
    text-align: left;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
}
