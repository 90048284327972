.loading {
    display: flex;
    align-items: center;
    justify-content: center;
}

.card {
    margin-bottom: 25px;
    margin-top: 10px;
}

.cardContent {
    min-height: 200px;
    padding: 20px;
}

.loadingContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
}

.headings {
    display: flex;
    width: 100%;
}

.typeHeading, .statusHeading {
    color: #757575;
}

.typeHeading {
    margin-left: 10px;
    width: 30%;
}

.statusHeading, .statusContent {
    width: 20%;
}

.statusContent {
    margin-left: 10px;
}

.trainingCard {
    width: 100%;
    background-color: #f7f7f7;
    border-radius: 6px;
    height: 40px;
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.footer {
    border-top: 1px solid #e5e7eb;
    width: 100%;
    padding: 14px 20px
}

.headerText {
    margin-bottom: 20px;
    color: #757575;
}
