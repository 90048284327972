@use "src/variables";

.card {
    background: #fff;
    border-radius: 8px;
    box-shadow: none;
    height: 81px;
    min-width: 220px;
    padding: 20px;
    margin: 0 10px;
    transition: transform 600ms ease-in-out;
    cursor: pointer;
    text-decoration: none;


    &:first-of-type {
        margin-left: 0px;
    }

    &:hover, &:focus {
        background-color: variables.$default-background-color;
        box-shadow: 0px 1px 4px #0000001F;
    }

    &:active {
        color: inherit;
    }
}

.visibleShadow {
    box-shadow: 0px 1px 4px #0000000F;
}

.cardTitle {
    font-weight: 700;
    line-height: 20px;
    color: variables.$title-font-color;
}

.subtitle {
    color: #68707D;
    font-size: 12px;
}

.badge {
    padding: 2px 10px;
    margin: 0 0 0 10px;
    background-color: variables.$default-background-color;
    color: variables.$badge-font-color;
    font-weight: 500;
    font-size: 12px;
    border-radius: 20px;
}
