@import "node_modules/sassdash/index";
@import "node_modules/sass-svg/index";

.container {
    margin: 50px 20px 30px;
}

.formContainer {
    max-width: 300px;
}

.drawerHeader {
    font-size: 20px;
    font: normal normal medium 20px Inter;
    letter-spacing: 0px;
    color: #111827;
}

.drawerText {
    font: normal normal normal 16px Inter;
    letter-spacing: 0px;
    color: '#727272';
}

.cancelButton, .drawerCancelButton {
    background-color: #fff;
    color: #111827;
}

.inputLabel {
    color: #111827;
    text-align: left;
    font: normal normal medium 18x Inter;
    letter-spacing: 0px;
    padding-bottom: 15px;
}

.inputContainer {
    padding: 15px 0;
}

.input {
    display: block;
    padding: 8px 12px;
    border: solid 1px #D1D5DB;
    border-radius: 6px;
    font-size: 16px;
}

.questionInputContainer {
    width: 40%;
    margin-right: 30px;
}

.inputRow {
    margin: 15px 0;
    display: flex;
}

.drawerButton, .drawerCancelButton {
    margin-right: 30px;
}

.saveButton {
    margin-top: 30px;
}

.passwordInput {
    max-width: 800px;
}

.errorText {
    color: #dc3545;
    margin-top: 10px;
}

.footerContainer {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    align-content: center;
}

.select {
    width: 100%;
    padding: 0 33px 0 13px;
    line-height: 34px;
    font-size: 16px;
    background: #fff;
    border: 1px solid #D1D5DB;
    border-radius: 6px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

    appearance: none;
    &::-ms-expand {
        display: none;
    }

    @include svg((
        viewBox: "0 0 10 7",
        width: "10",
        height: "7",
    )) {
        @include svg("path", (
            fillRule: "evenodd",
            clipRule: "evenodd",
            d: "M0.292893 0.377854C0.683416 -0.0126701 1.31658 -0.0126701 1.7071 0.377854L4.99999 3.67075L8.29288 0.377854C8.6834 -0.0126702 9.31657 -0.0126702 9.70709 0.377854C10.0976 0.768378 10.0976 1.40154 9.70709 1.79207L5.7071 5.79207C5.31657 6.18259 4.68341 6.18259 4.29289 5.79207L0.292893 1.79207C-0.0976309 1.40154 -0.0976309 0.768378 0.292893 0.377854Z",
            fill: "#68707D",
        ))
    }

    background-repeat: no-repeat;
    background-position: calc(100% - 13px) center;
}

.validationContainer {
    margin-top: 20px;
}

.validationTitle {
    margin: 10px 0;
}

.passwordInputContainer {
    margin-top: 20px;
}