.subtitle {
    font-weight: 600;
    font-size: 14px;
    color: #374151;
    padding-bottom: 5px;
    margin-top: 20px;
}

.formWrapper {
    padding: 20px;
}