.downloadTemplate {
    margin-bottom: 50px;
    padding: 13px 26px;
    font-size: 14px;
}

.formInput {
    width: 300px;
}

.fileInput {
    width: 475px;
}

.form {
    margin-left: 20px;
    padding-top: 15px;
}
