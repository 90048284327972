@use "/src/variables";

.buttonContainer {
    display: flex;
}

.button {
    margin-left: 20px;
}

.action {
    border: none;
    color: variables.$purple-accent;
    background: none;
    padding: 0;
    cursor: pointer;
}
