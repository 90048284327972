.sectionContainer {
    padding: 30px;
    border-bottom: solid 1px #E5E7EB;
}

.title {
    color: #222;
    font-size: 18px;
    font-weight: 700;
    line-height: 32px;
    padding-bottom: 20px;
}

.subTitle {
    margin-bottom: 8px;
}

.toggleContainer {
    margin-bottom: 30px;
}
