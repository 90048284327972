@use "/src/variables";

.container {
    max-width: 500px;
    border: solid 1px #D1D5DB;
    border-radius: 6px;
    box-shadow: 0px 1px 4px #0000000D;
    max-height: 360px;
    display: flex;
    flex-direction: column;
    overflow: scroll;
}

.table {
    display: inline-grid;
    width: 100%;
    grid-template-columns: 1fr;

    & > div {
       @for $i from 0 through 9 {
           &:nth-child(20n - #{$i}) {
               background-color: variables.$conversation-background-color;
           }
       }
    }
}

.headerCell {
    font-size: 12px;
    background-color: #F9FAFB;
    padding: 13px 15px;
    text-transform: uppercase;
    color: #68707D;
    font-weight: 500;
}

.dataCell {
    padding: 8px 13px;
    border-bottom: solid 1px #D1D5DB;
    background-color: #fff;
}

.search {
    margin: 10px 16px;
}

.counter {
    margin: 25px 16px 18px 16px;
}

.scrollable {
    flex-grow: 1;
    overflow: scroll;
}
