.version {
    width: fit-content;
}

.reportList {
    margin: 0;
}

.report {
    padding: 40px;
    text-align: center;
    letter-spacing: 0;
    color: #68707D;
    opacity: 0.7;
    text-transform: uppercase;
}
