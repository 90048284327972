@use "src/variables";

.errorFrameWrapper {
    margin: calc(30px * 2) 30px 30px;
}

.formContainer {
    display: flex;
    width: 65%;
    flex-direction: column;
}

.buttonContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 45px;
}

.validation {
    color: variables.$status-red;
    margin-top: 5px;
    margin-bottom: -15px;
}

.multiLineText {
    width: 100%;
    height: 100%;
    min-height: 200px;
}

.homepageButton {
    border: 1px solid #D1D5DB;
    color: variables.$badge-font-color;
    background: #FFFFFF 0% 0% no-repeat padding-box;
}
