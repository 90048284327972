@use "/src/variables";

.tabContainer {
    display: flex;
}

.headerUnderline {
    border-bottom: solid 1px #E5E7EB;
}

.tab {
    display: block;
    margin: 0px 18px;
    padding: 16px 4px 14px 4px;
    color: #68707D;
    text-decoration: none;
    font-weight: 500;
    border-bottom: solid 2px transparent;
}

.active {
    color: variables.$tipro-accent;
    border-bottom-color: variables.$tipro-accent;
}

.activeTabAlign {
    margin-left: auto;
}

.loadingMask {
    min-height: 600px;
}