.drawerContainer {
    height: 100%;
    width: 800px;
    position: fixed;
    right: 0;
    z-index: 100;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 4px 12px #0000003F;
    border: 1px solid #0000000D;
    background-color: white;
    transition: transform 450ms ease-out;
    transform: translateX(100%);

    @media (max-width: 800px) {
        width: 600px;
    }
}

.wide {
    width: 950px;
}

.padding {
    padding: 20px 10px 20px 20px;
}

.entering {
    transform: translateX(0);
}

.leaving {
    transform: translateX(100%);
}

.drawerHeader {
    height: 24px;
    margin: 12px 0 32px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.drawerContent {
    overflow-y: auto;
    height: 100%;
}

.drawerFooter {
    margin-top: auto;
    padding: 10px;
}

.disableScrolling {
    height: 100%;
    overflow: hidden;
}
