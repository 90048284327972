.keyInfo {
    display: flex;
    flex-wrap: wrap;
    padding: 16px;

    > div {
        display: flex;
        flex-grow: 1;
        width: 30%;
    }

    dt, dd {
        margin: 0;
        padding: 0;
        flex: 1;
    }

    > div {
        display: flex;
        margin-top: 20px;
    }
}

.term {
    text-transform: uppercase;
    color: #68707D;
}
