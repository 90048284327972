.button {
    margin: 30px 0 0;

    @media (min-width: 960px) {
        margin: (30px * 1.5) 0 0;
    }
}

.textInput {
    width: 100%;
    max-width: 200px;
    background: #fff;
    border: 2px solid #929292;
    border-radius: 4px;
    box-sizing: border-box;
    color: #666;
    display: block;
    padding: 8px;
}
