
$carousel-control-width: 32px;
$control-padding: 7.5px;

.controlsContainer {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    pointer-events: none;
}

.controlCircle {
    background-color: #fff;
    border: 1px solid #E5E7EB;
    color: #111827;
    border-radius: 50%;
    padding: $control-padding;
    box-shadow: 0px 2px 8px #0000000F;
    cursor: pointer;
    pointer-events: auto;
    transform: translateX(-(calc($carousel-control-width / 2)));

    &:last-child {
        transform: translateX(-(calc($carousel-control-width / 2)) - $control-padding);
    }
    
    &:disabled {
        color: #9CA3AF;
        cursor: not-allowed;
    }
}
