.dateRange {
    display: flex;
    column-gap: 20px;
    max-width: 500px;
}

.dateInput {
    width: 157px;
    font-family: inherit;

    &:focus-visible {
        padding: 0px 8px;
    }
}
