@use "/src/variables";

.badge {
    display: inline-flex;
    align-items: center;
    background-color: #CCCCCC57;
    border-radius: 10px;
    padding: 2px 10px;
    margin-left: 8px;
}

.statusIndicator {
    width: 8px;
    height: 8px;
    margin-left: 14px;
    border: none;
    border-radius: 50%;
}

.open {
    background-color: variables.$status-green;
}

.closed {
    background-color: variables.$status-red;
}