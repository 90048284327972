.header {
    padding: 16px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #0000000f
}

.title {
    flex: 1;
    color: black;
    font-size: 18px;
    font-weight: 700;
}

.items {
    display: flex;
}
