.stepHeading {
    display: inline-flex;
    flex-wrap: wrap;
    font-size: 14px;
    color: #68707D;
    font-weight: 500;

    strong {
        font-family: inherit;
        color: #555;
        font-weight: bold;
        padding-right: 5px;
    }
}
