.company {
    font-weight: bold;
    display: inline-block;
    margin-right: 8px;
    font-size: 16px;
    margin-bottom: 8px;
}

.ref {
    font-size: 13px;
}

.period {
    font-weight: 500;
    margin-bottom: 16px;
}

.header {
    margin-bottom: 45px;
}