.details {
    display: flex;
    justify-content: space-between;
    max-width: 600px;

    dt, dd {
        margin: 0;
        padding: 0;
    }

    dt {
        color: #68707D;
        margin-bottom: 5px;
    }

    dd {
        color: #111827;
    }
}
