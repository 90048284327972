@use "src/variables";

.externalLink {
    display: inline-flex;
    font-family: inherit;
    font-weight: 600;
    text-transform: uppercase;
    text-decoration: none;
    font-size: 75%;
    padding: 10px 30px 10px 0px;
}

.icon {
    height: 40px;
    width: 40px;
    margin-top: -4px;
    margin-left: -8px;
    fill: variables.$purple-accent;
    transition: transform 0.5s;
}

.open {
    transform: rotate(-90deg);
}

.dropdownHeader {
    align-items: center;
    margin-left: auto;
    border: none;
    background-color: transparent;
    cursor: pointer;
    padding-top: 5px;
    display: inline-flex;
    font-family: inherit;
    font-weight: 500;
    text-decoration: none;
    color: #333;
    cursor: pointer;
}

.tableHeading {
    font-family: inherit;
    font-weight: 500;
    color: variables.$purple-accent;
}

.tableContainer {
    display: flex;
    max-width: 400px;
    border: 1px solid lightgrey;
    border-radius: 5px;
}
