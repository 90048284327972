@use "/src/variables";

.title {
    display: flex;
    align-items: center;
    color: variables.$form-title-font-color;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    padding: 20px;
}
