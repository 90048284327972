@use "/src/variables";

.createCompanyWrapper {
    padding: 20px;
}

.infoBlock {
    margin-top: 20px;

    & > p {
        color: variables.$search-font-color;
        font-size: 14px;
        font-weight: 400;
        margin: 0;
        line-height: 20px;
    }
}

.subtitle {
    font-weight: 600;
    font-size: 14px;
    color: #374151;
    padding-bottom: 5px;
    margin-top: 20px;
}

.nameTitle {
    color: variables.$search-font-color;
    font-size: 17px;
    font-weight: 700;
    padding-bottom: 10px;
}

