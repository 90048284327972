@use "../../variables";

.tableHeader {
    display: flex;
    justify-content: space-between;
}

.filterContainer {
    margin: 15px;
    display: flex;
    align-items: center;
}
