@use "src/variables.scss";
@import "node_modules/sassdash/index";
@import "node_modules/sass-svg/index";

@mixin select {
    width: 100%;
    padding: 0 33px 0 13px;
    cursor: pointer;
    line-height: 34px;
    font-size: 16px;
    background: #fff;
    border: 1px solid #D1D5DB;
    border-radius: 6px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

    appearance: none;
    &::-ms-expand {
        display: none;
    }

    @include svg((
        viewBox: "0 0 10 7",
        width: "10",
        height: "7",
    )) {
        @include svg("path", (
            fillRule: "evenodd",
            clipRule: "evenodd",
            d: "M0.292893 0.377854C0.683416 -0.0126701 1.31658 -0.0126701 1.7071 0.377854L4.99999 3.67075L8.29288 0.377854C8.6834 -0.0126702 9.31657 -0.0126702 9.70709 0.377854C10.0976 0.768378 10.0976 1.40154 9.70709 1.79207L5.7071 5.79207C5.31657 6.18259 4.68341 6.18259 4.29289 5.79207L0.292893 1.79207C-0.0976309 1.40154 -0.0976309 0.768378 0.292893 0.377854Z",
            fill: "#68707D",
        ))
    }

    background-repeat: no-repeat;
    background-position: calc(100% - 13px) center;
}

.select {
    @include select;
}

.error {
    outline: variables.$status-red;
}
