.container {
    display: flex;
    position: relative;
    align-items: center;
}

.textContainer {
    display: flex;
    justify-content: center;
    padding-left: 8px;
    padding-right: 8px;
    min-width: 220px;
}

.action {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    color: #666D7A;
    font-size: 13px;
}
