$padding-left: 20px;

.container {
    color: #111827;
    row-gap: 10px;
    display: flex;
    flex-direction: column;
}

.subject {
    font-size: 24px;
    padding-left: $padding-left;
    white-space: pre-wrap;
    overflow-wrap: break-word;
}

.added {
    padding: 17px 0;
    background: #F9FAFB 0 0 no-repeat padding-box;
    color: #68707D;
}

.paddedContainer {
    margin-left: $padding-left;
}

.message {
    padding-left: $padding-left;
    white-space: pre-wrap;
    overflow-wrap: break-word;
}
