@use 'src/variables';

.container {
    display: flex;
    width: 100%;
}

.option {
    text-align: center;
    vertical-align: middle;
    margin-right: 30px;
}

.label {
    margin-left: 10px;
    font-size: 14px;
    color: #374151;
    font-weight: 500;
    vertical-align: middle;
    cursor: pointer;
}

.radioSelect { 
    width: 34px;
    height: 34px;
    vertical-align: middle;
    cursor: pointer;

    &:checked {
        accent-color: variables.$purple-accent;
    }
}