@use "/src/variables";

.container {
    position: absolute;
    bottom: 0px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 20px;
    border-top: 1px solid #D1D5DB;
    background-color: #FFFFFF;

    > button {
        flex: 1;

        &:not(:last-child) {
            margin-right: 20px;
        }
    }
}

.additionalActionsContainer {
    margin-right: auto;
}

.primary {
    background: variables.$tipro-accent 0 0 no-repeat padding-box;
    max-width: 128px;
}

.secondary {
    background: #FFFFFF 0 0 no-repeat padding-box;
    border: 1px solid #D1D5DB;
    color: #374151;
    max-width: 128px;
}
