@use "src/variables";

.validationIconRow {
    margin-bottom: 1em;
    display: flex;
    align-items: center;

    &:last-of-type {
        margin-bottom: 0;
    }
}

.passed {
    fill: variables.$status-green;
}

.failed {
    fill: variables.$status-red;
}
