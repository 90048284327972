.reportInfo {
    display: flex;
}

.reportCard {
    margin-top: 20px;
    border: solid 1px #0000000f;
}

.footer {
    border-top: 1px solid #0000000f;
    padding: 16px 24px;
}

.exportBtn {
    margin-right: 24px;
    width: 160px;
}

.header {
    color: #6b7280;
    font-size: 14px;
}

.title {
    color: #4b5563;
    font-weight: bold;
    font-size: 20px;
    margin-right: 16px;
}