@use "/src/variables";

.createButtonWrapper {
    width: 100%;
    height: 300px;
    margin-top: 50px;
    display: grid;
    place-items: center;
    background-color: #ebecef;
    border-radius: 8px;
    box-shadow: 0px 1px 4px #0000000f;
}

.createButton {
    background-color: variables.$tipro-accent;
    width: 177px;
    height: 38px;
}