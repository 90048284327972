@use '/src/variables';

.card {
    margin-bottom: 25px;
}

.cardContent {
    padding: 16px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.infoBlock {
    display: flex;
    justify-content: center;
    align-content: center;
    text-align: center;

    border-right: 1px grey;
}

.date {
    display: block;
    font-weight: bold;
    font-size: 21px;
    text-align: center;
    margin-top: 1em;
    margin-bottom: 1em;
}

.footer {
    border-top: 1px solid #0000000f;
    padding: 16px;
}

.fileCount {
    font-weight: bold;
}

.generateButton {
    margin-left: 20px;
}

.smallSpinner {
    height: 10px;
    width: 10px;
}

.titleLink {
    flex: 1;
    font-size: 18px;
    font-weight: 700;
    color: variables.$tipro-accent;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

.header {
    padding: 16px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #0000000f
}

.items {
    display: flex;
}
