@use "/src/variables";

.ticketInfoBlock {
    display: flex;
}

.ticketDescription {
    padding: 20px;
    border-bottom: solid 1px #E5E7EB;
    color: variables.$title-font-color;

    & > :first-child {
        color: #68707D;
    }
}
