.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    background: #fff;
    min-height: 200px;
}

.spinner {
    width: 50px;
    height: 50px;
    border: solid 6px #374151;
    border-bottom-color: transparent;
    border-radius: 50%;
    animation: 900ms linear infinite spin;

    @keyframes spin {
        from { transform: rotate(0deg); }
        to { transform: rotate(360deg); }
    }
}

.text {
    margin-top: 20px;
    font-size: 16px;
}
