@use 'src/variables';

.secondary {
    padding: 9px 17px;
    background: variables.$purple-accent;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    background: #FFFFFF 0 0 no-repeat padding-box;
    box-shadow: 0 1px 4px #0000000D;
    border: 1px solid #D1D5DB;
    border-radius: 6px;
    color: #374151;
}
