.taxPeriods {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    > div {
        flex: 1;

        &:not(:first-child) {
            margin: 0 5px 0 10px;
        }
    }

}
