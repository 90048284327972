@use "/src/variables";

.container {
    position: fixed;
    display: flex;
    flex-direction: column;
    padding: 10px 16px;
    z-index: 100;
    background: #fff;
    border-radius: 6px;
    overflow: hidden;
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.05),
                0px 10px 15px -3px rgba(0, 0, 0, 0.1),
                0px 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.button {
    padding: 12px 16px;
    border-radius: 6px;
    background: none;
    border: none;
    text-align: left;
    color: #374151;
    min-width: 120px;
    cursor: pointer;

    &:focus-visible {
        background: #eee;
        outline: none;
    }

    &:hover {
        background: #eee;
    }
}

.redActionText {
    color: variables.$status-red;
}
