.tabSection {
    margin: -20px,
};

.loadingContainer {
    margin-left: auto;
    margin-right: 10px;
    height: 25px;
    width: 25px;
    border-width: 4px;
}
