@use "../../variables";
@use "../../helpers";

.tooltip {
    $width: 12px;
    $height: calc($width / 2);
    $background:#000000;
    $slide-distance: 5px;

    position: absolute;
    padding: 3px 20px;
    z-index: 100;
    pointer-events: none;
    background: $background;
    color: #efefef;
    box-shadow: variables.$tooltip-shadow;
    border-radius: 8px;
    font-size: 15px;
    word-wrap: none;
    white-space: nowrap;

    transition: opacity ease variables.$ui-animation-duration,
                margin ease variables.$ui-animation-duration;

    opacity: 0;
    margin: 0px 0px 0px 0px;

    &.visible {
        opacity: 0;

        @media (min-width: 600px) {
            opacity: 0.75;
        }
    }

    &::after {
        position: absolute;
        display: block;
        content: '';
    }

    &.top {
        transform: translate(-50%, -100%) translateY(-$height);

        &.visible {
            margin: -$slide-distance 0px 0px 0px;
        }

        &::after {
            left: 50%;
            bottom: 0px;
            transform: translate(-50%, 100%);
        }
    }

    &.right {
        transform: translate(0%, -50%) translateX($height);

        &.visible {
            margin: 0px 0px 0px $slide-distance;
        }

        &::after {
            left: 0px;
            top: 50%;
            transform: translate(-100%, -50%);
        }
    }

    &.bottom {
        transform: translate(-50%, 0%) translateY($height);

        &.visible {
            margin: $slide-distance 0px 0px 0px;
        }

        &::after {
            left: 50%;
            top: 0px;
            transform: translate(-50%, -100%);
        }
    }

    &.left {
        transform: translate(-100%, -50%) translateX(-$height);

        &.visible {
            margin: 0px 0px 0px -#{$slide-distance};
        }

        &::after {
            right: 0px;
            top: 50%;
            transform: translate(100%, -50%);
        }
    }
}
