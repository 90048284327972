.formWrapper {
    padding: 20px;
}

.title {
    color: #444;
    font-size: 16px;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 10px;
}

.subTitle {
    margin-bottom: 6px;
}

.squares, .selected {
    width: 200px;
    height: 100px;
    flex-shrink: 0;
    border-radius: 6px;
    background: #fff;
    border: 1px solid #BBB;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 32px;

    &:hover {
        background-color: #eee;
        color: #444;
        cursor: pointer;
    }
}

.selected {
    background-color: #951B81;
    color: #fff;
}

.squaresContainer {
    display: flex;
    gap: 10px;
}

.hiddenInput {
    opacity: 0;
    width: 0;
    height: 0;
    position: absolute;
}
