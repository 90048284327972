.filter {
    display: flex;
    align-self: flex-end;
    justify-content: flex-end;
    width: 10%;
    margin-bottom: 20px;
}

.page {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.text {
    font-weight: 500;
}
