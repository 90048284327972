@use "/src/variables";
$iconSize: 20px;

.count {
    position: relative;
    top: -8px;
    left: 34px;
    height: $iconSize;
    min-width: $iconSize;
    border: 1px solid #eee;
    border-radius: ($iconSize / 5);
    box-sizing: border-box;
    padding: 0 2px;
    font-size: (3 * $iconSize / 4);
    font-weight: 500;
    text-align: center;
    background: variables.$tipro-accent;
    color: white;
}
