.container {
    position: fixed;
    top: 0;
    left: 250px;
    right: 0;
    height: 62px;
    background-color: #fff;

    &::after {
        display: block;
        content: "";
        position: absolute;
        top: 100%;
        left: 0px;
        width: 100%;
        height: 5px;
        background: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0.2),
            rgba(0, 0, 0, 0)
        );
    }
}

.contentWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 22px;
    height: 100%;
}

.sideActions {
    display: flex;
    justify-content: flex-end;
    column-gap: 30px;
}

.userContainer {
    display: flex;
    align-items: center;
    border: none;
    background: none;
    padding: 0;
    cursor: pointer;
}

.userName {
    margin-left: 10px;
    color: #666f7b;
}

.footerActions {
    display: flex;
    justify-content: center;
    padding: 10px;
    font-size: 16px;

    > button {
        text-transform: uppercase;
        font-weight: 600;
    }
}
