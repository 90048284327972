@use "/src/variables";

@mixin shadow-elevation($elevation: 1) {
    $shadow: 0px 3px variables.$elevation-blur-one variables.$elevation-shadow-one;

    @if ($elevation == 2) {
        $shadow: 0px 3px variables.$elevation-blur-two variables.$elevation-shadow-two;
    } @else if ($elevation == 3) {
        $shadow: 0px 3px variables.$elevation-blur-three variables.$elevation-shadow-three;
    }

    box-shadow: $shadow;
}

