.typeahead {
    padding-left: 20px;
    padding-right: 20px;

    ul {
        list-style: none;
        display: flex;
        flex-direction: column;
        max-height: 240px;
        overflow-x: hidden;
        margin-top: 4px;
        padding: 4px 0;
        background-color: #fff;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-shadow: 0 5px 10px rgba(0,0,0,.2);
        visibility: hidden;

        &.suggestions {
            visibility: visible;
        }

        &:before {
            content: '';
        }

        > li > .suggestion {
            background: none;
            border: none;
            color: #666D7A;
            font-size: 13px;
            padding: 6px;
            font-weight: 500;
            cursor: pointer;
        }
    }
}

.loading {
    display: flex;
    justify-content: center;
    overflow: hidden;
}
