.userFormContentWrapper {
    padding: 0px 20px 20px;
    margin-right: 60px;
}

.userDetails {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0 20px;

    & div {
        margin-top: 20px;
    }

    & input {
        width: 100%;
    }
}
