.container {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
}

.actionsContainer {
    display: flex;

    > div:first-child {
        flex: 1;
    }
}


