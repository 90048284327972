$default-background-color: #F3F4F6;
$conversation-background-color: #F9FAFB;
$purple-accent: #951b81;
$tipro-accent: #951B81;
$nav-background: #202A34;

$body-color: #333;
$heading-color: #555;

// Typography
$base-font-color:  $body-color;
$base-font-family: Inter;
$base-font-size:   16px;
$base-line-height: 1.5625;
$title-font-color: #111827;
$form-title-font-color: #222222;
$badge-font-color: #1F2937;
$search-font-color: #68707D;

$status-green: #28A745;
$status-red: #DC3545;
$error-red: #C83341;
$warn-color: #FF8B07;
$info-color: #1276B5;

$status-green-light: #ECFDF5;
$status-red-light: #F5EFF6;
$warn-color-light: #FFFBEB;
$info-color-light: #EBF4FF;

$status-green-dark: #065F46;
$warn-color-dark: #92400E;
$info-color-dark: #157989;

$sidebar-width: 250px;
$form-input-width: 50%;

// ELEVATION
$elevation-shadow-one: rgba(221, 221, 221, 1);
$elevation-shadow-two: rgba(0, 0, 0, 0.17);
$elevation-shadow-three: rgba(102, 102, 102, 1);

$elevation-blur-one: 6px;
$elevation-blur-two: 15px;
$elevation-blur-three: 40px;

$base-button-elevation: 0px 1px 2px rgba(0, 0, 0, 0.05);
$tooltip-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.27);
$shadow-transition: box-shadow 0.3s ease-out;
$ui-animation-duration: 300ms;

