@use "src/variables.scss";
@import "~sassdash";
@import "~sass-svg";

.input {
    appearance: none;
    width: 16px;
    height: 16px;
    border: 1px solid #D1D5DB;
    border-radius: 4px;

    &:checked {
        @include svg((
            viewBox: "0 0 10 8",
            width: "10",
            height: "8",
        )) {
            @include svg("path", (
                d: "M9.04725 1.07501C9.23472 1.26254 9.34004 1.51685 9.34004 1.78201C9.34004 2.04718 9.23472 2.30149 9.04725 2.48901L4.04725 7.48901C3.85972 7.67648 3.60542 7.7818 3.34025 7.7818C3.07509 7.7818 2.82078 7.67648 2.63325 7.48901L0.633251 5.48901C0.451093 5.30041 0.350298 5.04781 0.352577 4.78561C0.354855 4.52342 0.460024 4.2726 0.645432 4.08719C0.83084 3.90179 1.08165 3.79662 1.34385 3.79434C1.60605 3.79206 1.85865 3.89286 2.04725 4.07501L3.34025 5.36801L7.63325 1.07501C7.82078 0.887542 8.07509 0.782227 8.34025 0.782227C8.60542 0.782227 8.85972 0.887542 9.04725 1.07501Z",
                fill: "#fff",
            ));
        }

        background-color: variables.$tipro-accent;
        background-repeat: no-repeat;
        background-position: center center;
    }

}

.large {
    width: 32px;
    height: 32px;
    cursor: pointer;

    &:checked {
        @include svg((
            viewBox: "0 0 10 8",
            width: "20",
            height: "16",
        )) {
            @include svg("path", (
                d: "M9.04725 1.07501C9.23472 1.26254 9.34004 1.51685 9.34004 1.78201C9.34004 2.04718 9.23472 2.30149 9.04725 2.48901L4.04725 7.48901C3.85972 7.67648 3.60542 7.7818 3.34025 7.7818C3.07509 7.7818 2.82078 7.67648 2.63325 7.48901L0.633251 5.48901C0.451093 5.30041 0.350298 5.04781 0.352577 4.78561C0.354855 4.52342 0.460024 4.2726 0.645432 4.08719C0.83084 3.90179 1.08165 3.79662 1.34385 3.79434C1.60605 3.79206 1.85865 3.89286 2.04725 4.07501L3.34025 5.36801L7.63325 1.07501C7.82078 0.887542 8.07509 0.782227 8.34025 0.782227C8.60542 0.782227 8.85972 0.887542 9.04725 1.07501Z",
                fill: "#fff",
            ));
        }
    }
}

