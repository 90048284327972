.QRcontainer {
    max-width: 250px;
    max-height: 250px;
    margin-bottom: -25px;
}

.textInput {
    width: 100%;
    max-width: 200px;
}

.code {
    margin-top: 10px;
    color: #555;
    margin-bottom: -25px;
}
