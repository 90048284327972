.clickableDate {
    outline: none;
    background: none;
    border: none;
    color: #951b81;
    box-shadow: none;
    padding: 0px;
    font-size: 14px;
    font-weight: bold;
}
