@use "/src/variables";

.card {
    height: 167px;
}

.panelContentWrapper {
    display: flex;
}

.cardTitle, .cardSubtitle {
    display: flex;
    text-decoration: none;
    
    &:hover {
        text-decoration: underline;
    }
}

.companyTitle {
    font-weight: 700;
    color: variables.$tipro-accent;
}

.companyNumber {
    font-weight: 300;
    color: variables.$search-font-color;
    margin-left: 10px;
}

.cardSubtitle {
    width: 34%;
    color: variables.$tipro-accent;
}
