.container {
    flex: 1;
}

.filters {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    justify-content: space-between;
}
