.carousel {
    width: 100%;
    height: 90px;
    position: relative;
}

.itemContainer {
    width: calc(100% - 20px);
    height: 100%;
    padding-left: 1px;
    position: absolute;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
}
