.permissionsLabel {
    margin: 50px 0 10px;
}

.grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

.permissionsPresetContainer {
    width: 100%;
    padding: 0 0 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;

    & > button {
        width: 150px;
    }

    .selectedPreset:focus {
        background-color: #d1d5db;
    }
}

