.nameRow {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    & > div {
        width: 50%;
        max-width: 400px;
        padding-right: 40px;
        
        &:not(:first-child) {
            margin-top: 0;
            padding-right: 0;
        }
    }
}

.table {
    max-width: 100%;
    width: 100%;
    margin-top: 50px;
    border: none;
    box-shadow: none;
}

.tableHeader {

    & > div {
        background-color: #fff;
        color: #4B5563;
        padding: 10px;
        border-bottom: solid 2px #D1D5DB;

        &:first-child {
            position: sticky;
            left: 0;
            font-weight: 700;
            font-size: 14px;
        }
    }
}

.tableRows {
    max-height: 800px;
    
    & > div {
        height: 85px;
        border-bottom: none;
    }
    
    & > :nth-child(10n + 1) {
        position: sticky;
        left: 0;
        display: flex;
        align-items: center;
        font-weight: 700;
    }
}