.loader {
    height: 20px;
    background: #ddd;
    border-radius: 20px;
    position: relative;
    overflow: hidden;
}

.shimmer {
    position: absolute;
    height: 100%;
    width: 100%;

    background: linear-gradient(
        90deg, rgba(255,255,255,0) 0%, 
        rgba(244,244,244,0.72) 25%, 
        rgba(241,241,241,0.88) 37%, 
        rgba(238,238,238,1) 50%, 
        rgba(241,241,241,0.88) 62%, 
        rgba(244,244,244,0.75) 75%, 
        rgba(255,255,255,0) 100%
    );

    background-position: -100% 0;
    background-size: 200% 100%;
    background-repeat: no-repeat;
    animation: shine 2s infinite;
}

@keyframes shine {
    100% {
        background-position: -100% 0;
    }
    0% {
        background-position: 200% 0;
    }
}