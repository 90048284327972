.showPassword {
    display: flex;
    align-items: center;
    height: 40px;
    cursor: pointer;
    background: transparent;
    border: none;
    padding: 0;
    margin-top: auto;
    margin-left: 15px;
}

.showPasswordIcon {
    transition: fill 0.3s ease;
}

.buttonText {
    text-transform: uppercase;
    display: inline-block;
    margin-left: 7px;
}

.container {
    display: flex;
}
