.container {
    list-style: none;
    display: flex;
    flex-direction: column;
    max-height: 240px;
    overflow-x: hidden;
    padding: 0;
    margin: 0;

    > li > .item, .button {
        background: none;
        border: none;
        color: #666D7A;
        font-size: 13px;
        padding: 6px;
        font-weight: 500;

        &.button {
            cursor: pointer;
        }
    }
}

.margin {
    margin: 0 10px 10px;
}
