@use "/src/variables";

.cardTab {
    max-width: 1000px;
}

.headersContainer {
    border-bottom: 3px solid variables.$conversation-background-color;
}

.tabButton {
    position: relative;
    background-color: transparent;
    color: #666666;
    box-shadow: none;
    padding: 20px;
    font-size: 14px;

    &:hover {
        box-shadow: none;
    }
}

.activeTab::after  {
    content: "";
    position: absolute;
    bottom: -3px;
    left: 50%;
    transform: translateX(-50%);
    height: 3px;
    width: calc(100% - 40px);
    background-color: variables.$tipro-accent;
    border-radius: 6px;
    overflow: hidden;
}
