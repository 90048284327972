.container {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 18px;
    padding: 25px 25px 0;
}

.title {
    flex: 1;
    margin: 0px;
    color: #111827;
    font-size: 24px;
    font-weight: 700;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}