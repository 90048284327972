@use 'src/variables';

.modalContainer {
    height: 50%;
    max-height: 600px;
    min-width: 250px;
    width: 50%;
    position: fixed;
    top: 15%;
    left: calc((100% + variables.$sidebar-width) / 2);
    z-index: 50;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px;
    box-shadow: 0px 4px 12px #0000003F;
    border: 1px solid #0000000D;
    border-radius: 8px;
    background-color: white;
    transition: transform 200ms ease-out,
                opacity 200ms ease-out;
    transform: translate(-50%, 10px);
    opacity: 0;
    overflow: hidden;
}

.entering {
    transform: translate(-50%, 0);
    opacity: 1;
}

.leaving {
    transform: translate(-50%, 10px);
    opacity: 0;
}

.modalHeader {
    height: 24px;
    margin: 12px 0 32px 0;
    display: flex;
    justify-content: space-between;
}

.modalContent {
    height: 100%;
    padding-bottom: 20px;
    overflow: auto;
}

.modalFooter {
    display: flex;
    align-items: center;
    margin-left: auto;
    padding: 10px;
}
