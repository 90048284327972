@use '/src/variables';

.container {
    display: flex;
    align-items: stretch;
    background: #fff;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1),
                0px 1px 2px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
}

.sidebar {
    width: 280px;
    border-right: solid 1px #E5E7EB;
    padding: 30px;
    display: flex;
    flex-direction: column;
}

.name {
    margin: 17px 0px 4px 0px;
    font-size: 16px;
    font-weight: 600;
    color: #111827;
}

.department {
    font-weight: 400;
    color: #68707D;
}

.email {
    overflow-wrap: break-word;
}

.details {
    margin-top: 20px;
    font-size: 14px;
    line-height: 20px;

    dt, dd {
        margin: 0px;
        padding: 0px;
    }

    > div {
        &:not(:first-child) {
            margin-top: 20px;
        }
    }

    dt {
        color: #111827;
        font-weight: 500;
        margin-bottom: 5px;
    }

    dd {
        color: #68707D;
    }
}

.sidebarFooter {
    margin-top: auto;
}

.content {
    flex: 1;
}

.schemeBadge {
    width: 225px;
    height: 30px;
    display: flex;
    gap: 5px; 
    overflow: hidden;
    border: 1px solid #EBEDF0;
    border-radius: 20px;
    padding: 5px;
    cursor: pointer;
    text-decoration: none;

    & > div {
        min-width: 15px;

        & > svg {
            margin: auto 5px;
            color: #666666;
        }
    }

    &:hover {
        color: variables.$tipro-accent;
        text-decoration: underline;
    }
}

.schemeText {
    font-weight: 700;
    color: variables.$tipro-accent;
}
