.card {
    position: relative;
    background: #fff;
    border-radius: 6px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1),
                0 1px 2px rgba(0, 0, 0, 0.06);

    &.padding {
        padding: 16px;
    }
}

.content {
    padding: 10px;
}

.actionCard {
    @extend .card;
    border: 0;
    text-align: left;
}
