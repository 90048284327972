@use "/src/variables";

.numberSummary {
    flex: 1;
    width: 25%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: 500;
    border-right: 1px solid #f3f4f6;

    & > p {
        margin: 10px 0;
    }
}

.displayNumbers {
    font-weight: 700;
    font-size: 21px;
    height: 25px;
    margin-bottom: 20px;
}

.viewButton {
    height: 35px;
    border-radius: 6px;
    margin: 25px 0;
    padding: 0px 25px;
    color: variables.$badge-font-color;
    font-weight: 600;
}

.skeletonLoader {
    height: 15px;
    width: 40px;
    margin: 5px 0;
}