@use "/src/variables";

.cardWrapper {
    margin: 10px 0;
}

.moreActionsAlign {
    display: flex;
    justify-content: flex-end;
    padding: 10px 10px 0  10px;
}

.companyInfo {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    min-height: 150px;
    padding: 10px 20px;
    border-bottom: 1px solid #E5E7EB;
}

.subtitle {
    font-weight: 600;
    font-size: 13.7px;
    line-height: 16px;
    color: #555555;
    margin-bottom: 12px;
}

.title {
    font-weight: 700;
    font-size: 17px;
    line-height: 20px;
    color: #444444;
}

.address, .employeeCount {
    font-size: 14px;
    line-height: 20px;
    color: #666666;
}

.addressLine {
    margin: 0;
}

.schemesSubtitle {
    margin: 0;
    padding-left: 20px;
    padding-top: 12px;
}

.schemes {
    display: flex;
    padding: 12px;
}

.linkedSchemes {
    flex-grow: 1;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-left: 5px;
}

.schemeAdd {
    flex-shrink: 1;
    margin-top: auto;
    margin-right: auto;
}

.schemeBadge {
    width: 225px;
    height: 30px;
    display: flex;
    gap: 5px; 
    overflow: hidden;
    border: 1px solid #EBEDF0;
    border-radius: 20px;
    padding: 5px;
    cursor: pointer;
    text-decoration: none;

    & > div {
        min-width: 15px;

        & > svg {
            margin: auto 5px;
            color: #666666;
        }
    }

    &:hover {
        color: variables.$tipro-accent;
        text-decoration: underline;
    }
}

.schemeText {
    font-weight: 700;
    color: variables.$tipro-accent;
}
