.container {
    display: flex;
}

.box {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    flex: 1 1 auto;
    border: 1px solid #D1D5DB;
    border-radius: 6px;
    box-shadow: 0 1px 4px #0000000D;
    margin: 5px;
    height: 100%;
    width: 100%;
    min-height: 84px;
    min-width: 128px;
}

.box input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    height: inherit;
    width: inherit;
}

.box:hover input ~ .checkmark {
    background-color: #eee;
}

.box input:checked ~ .checkmark {
    background-color: #eee;
}

.box input:checked ~ .checkmark:after {
    display: block;
}

.box .checkmark:after {
    top: 9px;
    left: 9px;
    border-radius: 50%;
    background: white;
}
