.container {
    padding: 20px;
}

.cardContainer {
    width: 440px;
    border: solid 1px #D1D5DB;
    border-radius: 6px;
    margin-top: 20px;
}

.cardContent {
    display: grid;
    padding: 20px;
}

.header {
    display: grid;
    grid-template-columns: repeat(2, 1fr, 1fr);
    padding: 16px;
    align-items: center;
    border-bottom: 2px solid #0000000f;
}

.titleContainer, .rowContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.roleTitle, .pointsTitle, .cancelTitle {
    color: #757575;
    font-weight: 500;
    margin-left: 10px;
}

.roleTitle {
    width: 70%;
}

.pointsTitle {
    width: 20%
}

.cancelTitle {
    grid-area: cancel;
}

.skeletonRow, .pointsRow, .roleRow, .cancelRow {
    background-color: #f7f7f7;
    height: 40px;
    margin: 6px 0;
    padding-left: 10px;
    align-items: center;
    display: grid;
}

.roleRow {
    width: 70%;
    border-radius: 6px 0 0 6px;
}

.pointsRow {
    width: 20%;
}

.cancelRow {
    width: 10%;
    border-radius: 0 6px 6px 0;
}

.skeletonRow {
    width: 100%;
    border-radius: 6px;
}

.cardFooter {
    border-top: 1px solid #E5E7EB;
    padding: 20px;
}

.selectContainer {
    padding: 0 20px 20px 20px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.select {
    width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.cancelButton {
    cursor: pointer;
}

.cancelIcon {
    padding-top: 4px;
    margin-left: 4px;
    width: 18px;
    fill: #858585;
}

.input {
    width: 100%;
}
