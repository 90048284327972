.crumbs {
    display: flex;
    align-items: center;
    margin: 14px 0 0 0;
    font-size: 11px;
    font-weight: 600;
    font-style: normal;
    color: #68707D;
    text-transform: uppercase;
    white-space: nowrap;
}

.crumb {
    display: flex;
    align-items: center;
}

.arrow {
    height: 9px;
    width: 20px;
    margin: 3px 0 0 12px;
    fill: none;
    stroke: #68707D
}

.crumbLink {
    text-decoration: none;
    color: #666D7A;
}
