.checkboxContainer {
    display: flex;
    align-items: center;
}

.checkbox {
    height: 21px;
    width: 21px;
    margin-right: 12px;
}
