@use "/src/variables";
@use "/src/helpers";

.wrapper {
    width: 34px;
    height: 34px;
    position: relative;
    border-radius: 6px;
    overflow: hidden;
    box-shadow: variables.$base-button-elevation;
    transition: variables.$shadow-transition;

    &:hover {
        @include helpers.shadow-elevation(2);
        transition: variables.$shadow-transition;
    }
}

.alignRight {
    display: flex;
    justify-content: flex-end;
}

.boldBorderlessWrapper {
    box-shadow: none;
    display: flex;
    align-items: center;

    &:hover {
        box-shadow: none;
        border: 1px solid #D1D5DB;
    }
}

.button {
    height: 100%;
    width: 100%;
    border-radius: 6px;
    border: 1px solid #D1D5DB;
    box-shadow: variables.$base-button-elevation;
    background: #fff;
    cursor: pointer;
}

.boldBorderless {
    height: 25px;
    background-color: transparent;
    border: none;
    box-shadow: none;
}

.horizontalDots {
    transform: rotate(90deg);
}
