@use "/src/variables";

.container {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    background: variables.$tipro-accent;
}
