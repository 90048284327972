@use "/src/variables";

$iconSizeMobile: 20px;
$iconSizeDesktop: 25px;

.icon {
    height: 40px;
    width: 40px;
    margin-top: -4px;
    margin-left: -8px;
    fill: variables.$purple-accent;
    transition: transform 0.5s;
}

.open {
    transform: rotate(-90deg)
}

.dropdownHeader {
    align-items: center;
    margin-left: auto;
    margin-top: 25px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    padding-top: 5px;
    display: inline-flex;
    font-family: inherit;
    font-weight: 500;
    text-decoration: none;
    color: #333;
    cursor: pointer;
}

.header {
    color: #68707D;
    font-weight: 500;
    display: flex;
    font-size: 14px;
}

.code {
    margin-top: 10px;
    color: #555;
    margin-bottom: -25px;
}
