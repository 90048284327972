.tableHeader {
    background-color: #f9fafb;
}

.schemeTitle {
    font-weight: bold;
    display: inline-block;
    margin-right: 8px;
    font-size: 16px;
    margin-bottom: 8px;
    margin-left: 20px;
}

.periodInfo {
    font-weight: 500;
    margin-bottom: 16px;
    margin-left: 20px;
}

.tableActions {
    display: flex;
    align-items: center;
    justify-content: center;
}

.tableContainer {
    padding:0  20px;
}
