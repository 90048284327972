@use 'variables.scss';

.input {
    padding: 8px 12px;
    border: solid 1px #D1D5DB;
    border-radius: 6px;
    width: variables.$form-input-width;
    font-size: 16px;
    outline: none;

    &:disabled {
        background: #D9D9D9;
        cursor: not-allowed;
        border: 1px solid #BBB;
    }
}

.large {
    width: 100%;
}

.error {
    border-color: variables.$error-red;
}
