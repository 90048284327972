.navigator {
    cursor: pointer;
    background: none;
    border: none;
    font-size: 13px;

    > svg {
        height: 9px;
        width: 20px;
        fill: none;
        stroke: #68707D;
        stroke-width: 2px;
    }

    &:disabled {
        cursor: not-allowed;
        color: #b2b2b3;

        > svg {
            cursor: not-allowed;
        }
    }
}

.left {
    transform: rotate(180deg);
}
