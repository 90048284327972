.container {
    display: flex;
    justify-content: space-between;
    color: #68707D;
}

.previousButton, .nextButton, .numberButton {
    background: none;
    border: none;
    border-top: solid 2px transparent;
    padding: 14px 16px 16px 16px;
    cursor: pointer;
}

.previousButton, .nextButton {
    display: flex;
    align-items: center;
    gap: 14px;

    &:disabled {
        cursor: not-allowed;
        color: #b2b2b3;
    }
}

.current {
    border-top-color: #951b81;
}
