@use "/src/variables";
@import "~sassdash";
@import "~sass-svg";

.searchInput {
    width: 500px;
}

.actionsCol {
    display: flex;
    gap: 10px;
}

.companyTableName {
    font-weight: 700;
    color: variables.$tipro-accent;
}

.tableContainer {
    margin-top: 20px;
}

.returnDash {
    display: inline-block;
    padding: 10px 10px 10px 0;
    margin: 0px 0px 10px;
}
