.replyButton {
    box-shadow: none;

    &:disabled {
        color: #9CA3AF80;
    }

    &:last-of-type {
        width: 38px;
        padding: 0;
    }
}

.fileIcon {
    width: 18px;
    fill: #9CA3AF;
    stroke: #9CA3AF;
    stroke-width: 2px;
    transform: rotate(320deg);
}

.input {
    display: none;
}

.alignButtonGroup {
    transform: translateY(0);
    transition: transform 250ms ease-out;
}