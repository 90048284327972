.detailsContainer {
    display: flex;
    max-width: 700px;
    justify-content: space-between;
    margin-bottom: 25px;
    bottom: 0;
    left: 0;
    font-size: 12px;
    color: #68707D;
}

.attachments {
    font-size: 14px;
    color: #951b81;
    text-decoration: none;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
}

.attachment {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.fileIcon {
    width: 32px;
    fill: #9CA3AF;
    stroke: #9CA3AF;
    stroke-width: 2px;
    margin-right: -4px;
    margin-left: 18px;
    padding-right: 13px;
    transform: rotate(320deg);
}