@use 'variables.scss';

.input {
    display: block;
    padding: 8px 12px;
    border: solid 1px #D1D5DB;
    border-radius: 6px;
    width: 100%;
    font-size: 16px;
    resize: none;
    box-shadow: 0px 1px 4px #b7b7b7;
    outline: none;
}

.resizable {
    resize: both;
}

.error {
    border-color: variables.$error-red;
}