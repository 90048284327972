.returnDash {
    margin: 0px 0px 14px;
}

.alignLeft > :first-child {
    margin-left: 0;
}

.headerSpacing {
    margin-top: 10px;
}