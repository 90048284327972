.tertiaryButton {
    height: 38px;
    padding: 0px 20px;
    background-color: #fff;
    color: #374151;
    box-shadow: 0px 1px 4px #0000000D;
    transition: box-shadow 250ms ease-out;
    border: 1px solid #D1D5DB;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;

    &:hover {
        cursor: pointer;
        box-shadow: 0px 2px 5px #00000063;
        transition: box-shadow 250ms ease-out;
    }

    &:disabled {
        cursor: not-allowed;
        color: #9CA3AF;
    }
}

.compact {
    height: 25px;
    padding: 0;
    border-radius: 6px;
    width: 48px;
}

.table {
    width: 83px;
    border-color: #DDDDDD;
    border-radius: 8px;
    box-shadow: none;
}