@use "/src/variables";

.container {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 250px;
    height: 100vh;
    background-color: variables.$nav-background;
}

.logoContainer {
    height: 62px;
    display: flex;
    align-items: center;
    padding: 62px 40px 0px;
}

.logo {
    margin: auto;
}

.navContainer {
    padding: 8px;
    margin-top: 55px;
}
