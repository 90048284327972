@use "/src/variables";

.groupWrapper {
    border: 1px solid #4B5563;
    border-radius: 8px;
    padding: 10px;
    display: flex;
    flex-direction: column;
}

.header {
    font-size: 22px;
    font-weight: 700;
    color: variables.$form-title-font-color;
    margin-bottom: 20px;
}

.checkboxContainer {
    display: flex;
    flex-direction: column;
    gap: 5px;
}
