@use "/src/variables";

.containerPadding > div {
    padding: 0;
    border: 0;
}

.loadingMask {
    height: 545px;
    margin-top: 100px;
}

.userDetails {
    position: relative;
    height: 545px;
    padding: 20px;
}

.detailRow {
    padding-bottom: 20px;
    color: variables.$title-font-color;

    & :first-child {
        padding-bottom: 5px;
        color: variables.$search-font-color;
    }
}

.payrollCategories {
    padding-top: 20px;
}

.frequencyGroup {
    color: variables.$search-font-color;
}

.frequencyPayrollsContainer :first-child {
    margin-left: 0px;
}

.payrollBadge {
    height: 25px;
    padding: 0 5px;
    margin: 10px 5px 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: #ebebeb;
    border: 2px solid #ebebeb;
    border-radius: 6px;
}

.editUserButton {
    position: absolute;
    bottom: 0;
    margin-bottom: 20px;
}
