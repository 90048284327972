@use "/src/variables";
@use "/src/helpers";

.button {
    padding: 9px 17px;
    border: none;
    border-radius: 6px;
    background: variables.$tipro-accent;
    box-shadow: variables.$base-button-elevation;
    transition: variables.$shadow-transition;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;

    &:hover {
        @include helpers.shadow-elevation(2);
        transition: variables.$shadow-transition;
    }

    &:disabled {
        cursor: not-allowed;
        background: #9CA3AF;
        box-shadow: none;
    }
}
