.table {
    width: 100%;
    border-collapse: collapse;
    color: #68707D;
    overflow: hidden;
    border-radius: 6px;

    th, td {
        padding: 20px;
    }

    thead {
        text-align: left;

        tr {
            border-bottom: solid 1px #e8eaed;
            background-color: #f9fafb;
        }
    }

    th {
        text-transform: uppercase;
        font-weight: 500;
    }

    tr {
        &:nth-child(odd) {
            background: #fff;
        }

        &:nth-child(even) {
            background: #f9fafb;
        }
    }
}

.heading {
    text-align: left;

    tr th {
        border-bottom: solid 1px #e8eaed;
        background-color: #f9fafb;
    }
}

.noData {
    padding: 30px;
    text-transform: uppercase;
    font-weight: bold;
    color: #68707D;
    font-size: 14px;
}
