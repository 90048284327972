.container {
    position: relative;
    padding: 8px;
    width: calc(100% - 8px);
    overflow: hidden;
    white-space: nowrap;
    color: #757575;
    user-select: none;
}

.file {
    padding: 4px;
    display: flex;
    align-items: center;
    background: #EFEFEF 0 0 no-repeat padding-box;
    box-shadow: 0 1px 4px #0000000D;
    border-radius: 6px;
    color: #374151;
    font-size: 13px;
    font-weight: 500;

    &:not(:first-child) {
        margin-top: 6px;
    }
}

.fileClose {
      > svg {
         height: 16px;
         width: 16px;
         color: #374151;
     };
}

.action {
    padding: 0;
    margin: 0;
}
