@use "/src/variables";

.sitesWrapper {
    margin-top: 10px;
}

.actionsCol {
    display: flex;
    gap: 10px;
}

.siteName > a {
    color: variables.$tipro-accent;
    font-weight: 700;
}

.postcode {
    font-weight: 700;
}

.loadingMask {
    height: 1000px;
    background-color: variables.$default-background-color;
    background-color: transparent;
}
