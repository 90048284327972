.container {
    width: 100%;
    background: #fff;
    border: solid 1px #E5E7EB;
    border-radius: 6px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1),
                0px 1px 2px rgba(0, 0, 0, 0.06);
}

.headerWrapper {
    padding: 20px;
    border-bottom: solid 1px #E5E7EB;
}

.title {
    margin: 0px;
    padding: 0px;
    font-size: 14px;
    color: #111827;
    font-weight: 500;
}

.header {
    margin-top: 16px;
}

.scrollableContent {
    max-height: 1080px;
    overflow-y: auto;
}

.footer {
    border-top: solid 1px #E5E7EB;
}
