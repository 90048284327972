div.ticketDrawer {
    padding: 28px 30px 0px 40px;
}

div.ticketFooter {
    width: 100%;
    position: absolute;
    background-color: white;
    left: 0;
    bottom: 0;
    border-top: solid 1px #D1D5DB;
    padding: 20px 30px;
}