@use "src/variables.scss";

.wrapper {
    display: flex;
}

.container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    height: 100%;
    border: 2px solid #0000000f;
    border-radius: 15px;
    min-height: 250px;
}

.icon {
    fill: black;
}

.centerButton {
    justify-content: center;
}

.nativeInput {
    opacity: 0;
    display: contents;
}

.button {
    color: purple;
    display: block;
    cursor: pointer;
}

.centerIcon {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.error {
    outline: variables.$status-red;
}
