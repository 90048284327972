.messageHistoryContainer {
    width: 100%;
    max-height: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
}

.responseBoxContainer {
    display: flex;
    align-items: flex-start;
}

.avatar {
    margin: 26px 15px 0 0;

    & > button {
        background-color: #68707D;
    }
}

.rightAlign {
    margin-left: auto;
    flex-direction: row-reverse;
    
    & .avatar {
        margin: 26px 0 0 15px;
        
        & > button {
            background-color: #951b81;
        }
    }
}
