@use '/src/variables';

.notificationContainer {
    position: fixed;
    z-index: 1000;
    left: auto;
    top: 30px;
    right: 30px;
    width: 25vw;
    border-radius: 6px;
   
    &.warn {
        background-color: variables.$warn-color;
    }
    
    &.success {
        background-color: variables.$status-green;
    }
    
    &.error {
        background-color: variables.$status-red;
    }
    
    &.info {
        background-color: variables.$info-color;
    }
}

.confirmationInner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 15px 30px;
    font-size: 1.1em;
    color: #fff;
}

.typeIcon {
    width: 24px;
    height: 24px;
    fill: #fff;
}

.message {
    flex: 1 1 20%;
    margin-bottom: 20px;

    @media (min-width: 700px) {
        margin-bottom: 0px;
    }
}

.iconClass {
    width: 24px;
    height: 24px;
    cursor: pointer;
    fill: #fff;
}
