.title {
    display: flex;
    flex-wrap: wrap;

    > div {
        &.companyName {
            flex: 1;
        }
    }
}

.payroll {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
}

.footer {
    display: flex;
}

.action {
    padding: 8px 14px 8px;
    background: #FFFFFF 0 0 no-repeat padding-box;
    box-shadow: 0 1px 4px #0000000d;
    border: 1px solid #D1D5DB;
    border-radius: 6px;
    color: #374151;
    margin-left: auto;
}

.payrollCard {
    width: 100%;
    flex: calc(50% - 20px);
    padding: 14px;
    cursor: pointer;

    > div {
        display: flex;
        align-items: center;
        margin-top: 4px;

        &.payroll {
            margin-top: 10px;
            margin-left: auto;
        }
    }
}
