@use '/src/variables';

.confirmationContainer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    opacity: 1;
    
    animation: scale-out 1s ease;
    
    &.warn {
        background-color: variables.$warn-color;
    }
    
    &.success {
        background-color: variables.$status-green;
    }
    
    &.error {
        background-color: variables.$status-red;
    }
    
    &.info {
        background-color: variables.$info-color;
    }
    &.none {
        background-color: none;
    }
}

.confirmationInner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 15px 30px;
    font-size: 1.1em;
    color: #fff;
}

.typeIcon {
    width: 24px;
    height: 24px;
    fill: #fff;
}

.message {
    flex: 1 1 20%;
    margin-bottom: 20px;

    @media (min-width: 700px) {
        margin-bottom: 0px;
    }
}

.actions {
    display: flex;
    align-content: center;
    align-items: center;
    margin-left: 10px;
}

.action {
    margin-right: 22px;
    background-color: #fff;
    color: black;
    width: 90px;
    text-transform: none;
}

.iconClass {
    width: 24px;
    height: 24px;
    cursor: pointer;
    fill: #fff;
}
