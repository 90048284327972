@use "/src/variables";

.link {
    display: flex;
    height: 40px;
    padding: 10px;
    color: #F3F4F6;
    text-decoration: none;
    font-weight: 500;
    border-radius: 6px;
    align-items: center;

    &:not(:last-child) {
        margin-bottom: 6px;
    }
}

.icon {
    margin-right: 15px;
}

.active {
    color: #fff;
    background-color: variables.$tipro-accent;
}
