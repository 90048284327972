@use "/src/variables";

.checkboxCell  {
    border-bottom: 1px solid #d1d5db;
    display: flex;
    justify-content: center;
    align-items: center;
}

.lastRow {
    border-bottom: none;
}

.shadeRow {
    background-color: variables.$conversation-background-color;
}