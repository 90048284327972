.row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0px;
}

.permissionName {
    font-size: 16px;    
}
