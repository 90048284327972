.container {
    display: flex;
    align-items: center;
}

.small {
    transform: translateX(-30px) scale(0.6);
}

.switch { 
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top:0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: 0.4s;
    border-radius: 22px;
    background-color: #d1d5db;
}

.slider::before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom:4px;
    background-color: #fff;
    border-radius: 22px;
    transition: 0.4s;
}

.switchInput:checked + .slider::before { 
    transform: translate(22px);
}

.switchInput:checked + .slider {
    background-color: #951B81;
}

.leftLabel {
    margin-right: 11px
};

.rightLabel {
    margin-left: 11px
}
