.formContent {
    padding: 20px;
    max-width: 700px;
}

.firstColumn, .currentTroncScheme {
    width: 300px;
    margin-right: 16px;
}

.secondColumn {
    width: 300px;
}

.currentTroncScheme {
    background-color: #D9D9D9;
}

