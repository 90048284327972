@use 'src/variables';

.label {
    font-size: 14px;
    margin-right: 10px;
    font-weight: 400;
}

.value {
    font-size: 14px;
    background-color: variables.$purple-accent;
    border-radius: 50%;
    color: white;
    padding: 3px 8px
}