@use "src/components/forms/form-controls/select.module.scss";

.buttonContainer {
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 10px;
}

.buttons {
    display: flex;
}

.previousButton, .nextButton, .numberButton {
    background: none;
    border: none;
    border-top: solid 2px transparent;
    padding: 14px 16px 16px 16px;
    cursor: pointer;
}

.previousButton, .nextButton {
    display: flex;
    align-items: center;
    gap: 14px;

    &:disabled {
        cursor: not-allowed;
        color: #b2b2b3;
    }
}

.filterText {
    margin: auto;
}

.select {
    @include select.select;
}
