.percentage {
    width: 55px;
    margin-right: 16px;
}

.amount {
    width: 150px;
    margin-right: 16px;
}

.deduction {
    width: 165px;
    margin-right: 16px;
}

.formWrapper {
    padding: 20px;
}
