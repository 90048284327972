.responseBoxContainer {
    position: relative;
}

.responseBox {
    position: relative;
    word-break: break-word;
    width: 700px;
    padding: 20px;
    margin-top: 25px;
    border: 1px solid #E5E7EB;
    background-color: #fff;

    &::after {
        content: "";
        position: absolute;
        width: 10px;
        height: 10px;
        border-top: 1px solid #E5E7EB;
        border-left: 1px solid #E5E7EB;
        top: 15px;
        left: -6px;
        background: #fff;
        transform: rotate(-45deg); 
    }
}

.right {
    &::after {
        left: calc(100% - 5px);
        transform: rotate(135deg); 
    }
}
