@font-face {
    font-family: 'Inter';
    src: url('./font/Inter/Inter-Light.ttf');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('./font/Inter/Inter-Regular.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('./font/Inter/Inter-Medium.ttf');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('./font/Inter/Inter-SemiBold.ttf');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('./font/Inter/Inter-Bold.ttf');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('./font/Inter/Inter-ExtraBold.ttf');
    font-weight: 800;
    font-style: normal;
}

