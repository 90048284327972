@use "/src/variables";

.headerBlock > div {
    padding: 0px;
    letter-spacing: 0px;

    & > div:last-of-type {
        padding: 0px;
    }
}

.closeMargin {
    margin-top: -60px;
}

.pageTitle {
    color: variables.$title-font-color;
    font-size: 24px;
    display: inline-flex;
    gap: 5px;
    width: 100%;

    & > :first-child {
        max-width: 80px;
        white-space: nowrap;
    }
}

.pageTitleContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    & > div {
        min-width: 200px;
        display: flex;
        justify-content: flex-end;
    }
}

.loadingContainer {
    margin-left: auto;
    margin-right: 10px;
    height: 25px;
    width: 25px;
    border-width: 4px;
}

.maskHeight {
    min-height: 75vh;
}