.container {
    position: absolute;
    flex-direction: column;
    top: 20px;
    left: 64px;
    margin: 0;
    background-color: #fff;
    max-width: 220px;
    box-shadow: 2px 6px 5px -2px rgb(0 0 0 / 20%);
    border-radius: 4px;
    cursor: default;
    border: 1px solid #ddd;
    display: flex;
    z-index: 1;
}

.filterInput {
    padding: 10px 10px 6px;
}
