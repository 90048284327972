.closeButton {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: 10px;
    border: none;
    background-color: transparent;
    cursor: pointer;
}

.closeIcon {
  width: 32px;
  height: 32px;
  fill: currentColor;
  color: #9CA3AF;
}
