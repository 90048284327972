.button {
    position: fixed;
    z-index: 100;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    margin: 0;
    padding: 0;
    transition: all 0.5s ease;
    
    :global(.__flag-user-is-not-tabbing) &:focus {
        outline: none;
    }
}

.visible {
    visibility: visible;
    opacity: 1;
}

.hidden {
    visibility: hidden;
    opacity: 0;
}

.icon {
    margin: 2px 0px 0px 0px;
    transform: rotate(90deg);
    fill: #fff;
    height: 40px;
    width: 40px;
}
