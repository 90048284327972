.button {
    border: none;
    font-weight: 700;
    background: none;
    padding: 0;
    text-decoration: underline;
    color: #951B81;
    font-weight: 700;
    cursor: pointer;
}

.outline {
    border: 1px solid #68707D;
    border-radius: 8px;
    padding: 10px;
    font-size: 16px;
    font-weight: 300;
    font-style: italic;
    color: #1F2937;
    cursor: default;

    & > span {
        font-weight: 300;
        color: #1F2937;
    }
}
    
