@use "/src/variables.scss";

.detailInput {
    width: variables.$form-input-width;
}

.formWrapper {
    padding: 20px;
}

