@use "/src/variables";

.pageControlWrapper {
    position: absolute;
    bottom: 0px;
    width: 100%;
    border-top: 1px solid #D1D5DB;
    padding: 20px;
    display: flex;
}

.formPageControl {
    min-width: 128px;
    margin-left: 20px;
    box-shadow: 0px 1px 4px #0000000D;
    font-weight: 500;
    cursor: pointer;
    background-color: variables.$tipro-accent;

    &:disabled {
        cursor: not-allowed;
        background-color: #D1D5DB;
        color: #374151;
        opacity: 0.8;
        box-shadow: none;
    }
}

.previous {
    color: #374151;
    background-color: transparent;
    border: 1px solid #D1D5DB;
}

.left {
    justify-content: flex-start;
}

.right { 
    justify-content: flex-end;
}

