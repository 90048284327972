.companiesWrapper {
    width: 100%;
    padding: 20px 0px;
    display: grid;
}

.listView {
    grid-template-columns: repeat(1, 100%);
    gap: 20px;
}

.gridView {
    grid-template-columns: repeat(2, 50%);
    gap: 20px;
}