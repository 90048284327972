
.attachmentsContainer {
    padding-left: 10px;
    top: 75%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

.attachmentFileIcon {
    margin-right: 4px;
    width: 18px;
    fill: #9CA3AF;
    stroke: #9CA3AF;
    stroke-width: 2px;
    transform: rotate(320deg);
}

.attachmentCancelIcon {
    padding-top: 4px;
    margin-left: 4px;
    width: 18px;
    fill: #858585;
}

.attachmentAlign {
    display: flex;
    padding-right: 10px;
    align-items: center;
}
