@use "/src/variables";

.panelContentWrapper {
    display: flex;
    flex-direction: column;
}

.card {
    min-height: 250px;
}

.cardTitle {
    display: flex;
    text-decoration: none;
}

.companyTitle {
    font-weight: 700;
}

.companyNumber {
    font-weight: 300;
    color: variables.$search-font-color;
    margin-left: 10px;
}

.cardContainer, .addSection {
    display: flex;
    padding: 10px 20px;
    align-items: flex-end;
}

.addSection {
    border-bottom: 1px solid rgba(0, 0, 0, 0.0588235294);
}

.addButton {
    margin-top: 18px;
    min-width: 94px;
}

.input {
    margin-right: auto;
}
