@use "/src/variables.scss";

.link {
    text-decoration: none;
    color: variables.$tipro-accent;
    font-weight: 700;

    &:hover {
        text-decoration: underline;
    }
}
