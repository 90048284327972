@use '/src/variables';

.validationTitle {
    font-weight: 600;
    margin-top: 40px;
    color: variables.$error-red;
}

.csvValidationWrapper {
    max-height: 600px;
    overflow: scroll;
    margin-top: 10px;
    margin-bottom: 50px;
    border-left: 4px solid variables.$error-red;
    padding-left: 10px;
}

.header {
    font-weight: 500;
    padding: 10px;
    background-color: #0000000D;
}

.msgWrapper {
    display: grid;
    grid-template-columns: auto 1fr;

    & > div {
        border: 1px solid #0000000D;
    }
}

.cellPadding {
    padding: 5px;
}

.instruction {
    padding-bottom: 4px;
}

