.sectionContainer {
    padding: 30px;
    border-bottom: solid 1px #E5E7EB;
}

.title {
    color: #222;
    font-size: 18px;
    font-weight: 700;
    line-height: 32px;
    padding-bottom: 10px;
}

.pointsButtonConatiner {
    width: 70px;
}

.numberInput {
    width: 48px;
}

.inputs {
    display: flex;
}

.saveButton {
    margin-top: auto;
    height: 40px;
}
