@use "/src/variables";

.container {
    width: 400px;
    margin: 100px auto 200px;

    & input {
        width: 100%;
    }
}

.title {
    text-align: center;
    margin: 0px 0px 60px 0px;
}

.errorMessage {
    display: block;
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 16px;
    color: red;
}

.headerBar {
    background-color: variables.$nav-background;
    display: flex;
    width: 100%;
    height: 120px;
}

.logo {
    margin: auto;
}
