@use "/src/variables";

.formWrapper {
    display: inline-block;
    margin: 0;
    max-height: 20px;
}

.inputLabel {
    position: relative;
    display: inline-flex;
    padding: 0px;
    transition: padding 250ms ease-out;
}

.inputLabelEdit {
    padding: 0px 10px;
    transition: padding 250ms ease-out;
}

.inputValue {
    cursor: text;
    border: 1px solid transparent;
}

.inputValueEdit {
    opacity: 0;
}

.inputValueLoading {
    visibility: hidden;
    width: 25ch;
}

.skeleton {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 25ch;
}

.defaultInput {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% + 20px);
    max-width: 50ch;
    font: inherit;
    color: inherit;
    text-align: inherit;
    padding: 0;
    background: inherit;
    border: 1px solid transparent;
    box-shadow: 0 1px 4px #0000000d;
    outline: none;
    transition: padding 250ms ease-out;
    opacity: 0;
}

.editingInput {
    padding: 0px 10px;
    cursor: text;
    background-color: #fff;
    border: 1px solid variables.$status-red;
    border-radius: 6px;
    transition: padding 250ms ease-out;
    opacity: 1;
}

.editIcon {
    position: absolute;
    right: -30px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    fill: #9ca3af;
    margin: auto 0 0 20px;
    cursor: pointer;
}

.spinner {
    height: 25px;
    width: 25px;
    border-width: 4px;
    margin-top: 5px;
}

.inlineEditValidation {
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 300px;
    padding: 5px 0;
    font-size: 14px;
    font-weight: 400;
    color: variables.$status-red;

    & > span:only-child {
        color: variables.$status-red;
    }
}
