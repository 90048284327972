@use "/src/variables";

.ticketMessagesContainer {
    position: relative;
    max-height: 835px;
    width: 100%;
    background-color: variables.$conversation-background-color;
    overflow: auto;
    scroll-behavior: smooth;
    transition: padding 250ms ease-out;
}

.userResponseOpen {
    padding-bottom: 20px;
    transition: padding 250ms ease-out;
}

.queryBar {
    padding: 5px 12px;
    border-bottom: 1px solid #E5E7EB;
    position: sticky;
    display: flex;
    top: 0;
    z-index: 50;
    background-color: #F9FAFB;

    & > input {
        height: 18px;
        padding: 18px 36px;
        background-color: variables.$conversation-background-color;
        font-size: 14px;
        border: none;
        border-radius: 0;    
    }
}
