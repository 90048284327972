@use "src/variables";

.stepperContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 60px;
}

.stepContainer {
    width: 117px;
    margin-right: 10px;
}

.stepBar {
    width: 100%;
    height: 4px;
    background-color: #E5E7EB;
}

.activeStep {
    background-color: variables.$purple-accent;
}

.currentStep {
    text-transform: uppercase;
    margin-top: 16px;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: #68707D;
}

.activeCurrentStep {
    color: variables.$purple-accent;
}

.stepTitle {
    font-weight: 500;
    color: variables.$title-font-color;
    font-size: 14px;
    line-height: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}