.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.left {
    width: 375px;
}

.right {
    display: flex;
    gap: 20px
}