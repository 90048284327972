@use "/src/variables";

.ticketInfoCol {
    padding: 20px 40px 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    
    &:first-child {
        padding-left: 20px;
    }

    &:last-child {
        justify-content: flex-start;
    }
}

.ticketInfoWrapper {
    padding: 0 10px 15px 0;
}

.ticketProperty {
    color: #68707D;
}

.ticketValue {
    color: variables.$title-font-color;
}