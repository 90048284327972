.actions {
    position: absolute;
    bottom: 15px;
    left: 0;
    width: calc(100% - 22px);
    display: flex;
    justify-content: space-between;
    border-top: 2px #E5E7EB solid;
    padding: 8px;

    > button {
        flex: 0;

        &:not(:last-child) {
            margin-right: 8px;
        }
    }
}

.heading {
    padding-left: 20px;
    padding-top: 20px;
}
