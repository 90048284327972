@use "/src/variables";

.contentWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    @media (min-width: 800px) {
        padding: 0 30px;
        box-sizing: border-box;
    }
}

.errorCard {
    display: flex;
    flex-direction: column;
    flex: 1 0 100%;
    max-width: 980px;
    background: #fff;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border: solid;
    border-width: 0px 0px 0px 4px;
    border-color: variables.$error-red;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    min-height: 660px;
}

.cardHeader {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    color: variables.$status-red;
    opacity: 1;
    display: flex;
    align-items: center;
    box-shadow: 0px 1px 4px #0000000F;

    @media (min-width: 600px) {
        height: 130px;
        font-size: 16px;
    }
}


.cardHeaderInner {
    text-align: left;
    display: flex;
    align-items: center;
    padding: calc(30px / 2) 30px;
}

.icon {
    fill: variables.$status-red;
    width: 23px;
    min-width: 23px;
    height: 20px;
    min-height: 20px;
}

.iconPadding {
    padding: 12.5px;
}

.cardContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1 1 100%;
    padding: calc(30px / 2);

    @media (min-width: 600px) {
        padding: 30px;
    }
}

.cardText {
    color: #666;
    margin: 0 0 25px;
    padding-left: 8px;
    font-size: 11px;

    @media (min-width: 600px) {
        font-size: 14px;
    }
}
