@import "~sassdash";
@import "~sass-svg";

.container {
    color: #D1D5DB;
    display: flex;
    align-items: center;
}

.input {
    padding: 8px 12px 8px 36px;
    border: solid 1px #D1D5DB;
    border-radius: 6px;
    width: 100%;
    font-size: 16px;
    background-color: #fff;
    background-position: 10px center;
    background-size: 16px 16px;
    background-repeat: no-repeat;

    &.transparent {
        background-color: transparent;
    }

    @include svg {
        @include svg("path", (
            d: "M6 2C3.79086 2 2 3.79086 2 6C2 8.20914 3.79086 10 6 10C8.20914 10 10 8.20914 10 6C10 3.79086 8.20914 2 6 2ZM0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6C12 7.29583 11.5892 8.49572 10.8907 9.47653L15.7071 14.2929C16.0976 14.6834 16.0976 15.3166 15.7071 15.7071C15.3166 16.0976 14.6834 16.0976 14.2929 15.7071L9.47653 10.8907C8.49572 11.5892 7.29583 12 6 12C2.68629 12 0 9.31371 0 6Z",
            fill-rule: "evenodd",
            clip-rule: "evenodd",
            fill: "#9CA3AF",
        ));
    }
}

.iconContainer {
    padding-left: 10px;
}

.icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
    fill: #9CA3AF;
}
