.loading {
    display: flex;
    align-items: center;
    justify-content: center;
}

.card {
    margin-bottom: 25px;
    margin-top: 10px;
}

.cardContent {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    min-height: 200px;
}

.infoBlock, .infoBlockMiddleColumn {
    display: flex;
    justify-content: center;
    align-content: center;
    text-align: center;
    border-bottom: 1px solid #E5E7EB;
}

.infoBlockMiddleColumn {
    border-left: 1px solid #E5E7EB;
    border-right: 1px solid #E5E7EB;
}

.smallSpinner {
    height: 10px;
    width: 10px;
}

.header {
    padding: 16px;
    display: flex;
    align-items: center;
    border-bottom: 2px solid #0000000f
}

.items {
    display: flex;
}

.subHeading {
    display: block;
    font-weight: bold;
    font-size: 21px;
    text-align: center;
    margin-top: 1em;
    margin-bottom: 1em;
}