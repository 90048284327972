@use "/src/variables";

.fileNameDownload {
    color: variables.$purple-accent;
    border: none;
    background-color: transparent;
    box-shadow: none;
    padding-left: 0;

    &:hover {
        box-shadow: none;
    }
}
