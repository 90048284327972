.formBottomContainer {
    position: sticky;
    bottom: 0;
    min-height: 82px;
    width: 100%;
    display: flex;
    padding: 20px;
}

.messageResponseContainer {
    background-color: #FFF;
    border: 1px solid #E5E7EB;
}

.messageContainer {
    display: flex;
    flex-direction: column;
}

.form {
    width: 100%;
}

.responseInput {
    display: flex;
    flex-grow: 3;
    width: 100%;
    height: 82px;
    padding: 30px 30px 30px 30px;
    margin-bottom: 10px;
    border: 0px solid #E5E7EB;
    border-radius: 4px;
    cursor: pointer;
    resize: none;
    transition: height 250ms ease-out;

    &::placeholder {
        color: #9CA3AF;
    }

    &:disabled {
        cursor: not-allowed;
        background-color: #F5F7FB;
    }
}

.focusInput {
    cursor: auto;
    height: 180px;
    box-shadow: 0px 3px 6px #00000063;
    transition: height 250ms ease-out;
}

.cancelButton {
    all:unset;
    cursor: pointer;
    width: 18px;
    height: 18px;
    margin-left: 4px;
}

.attachmentsContainer {
    display:flex;
    flex-direction: row;
}


.buttonGroup {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
    margin-right: 10px;
}

.replyButton {
    box-shadow: none;
    margin-right: 20px;

    &:disabled {
        color: #9CA3AF80;
    }

    &:last-of-type {
        width: 38px;
        padding: 0;
    }
}

.fileIcon {
    width: 18px;
    fill: #9CA3AF;
    stroke: #9CA3AF;
    stroke-width: 2px;
    transform: rotate(320deg);
}

.loadingSpinner {
    width: 20px;
    height: 20px;
    border-width: 4px;
    margin-right: 10px;
}