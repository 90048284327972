@use "/src/variables";

.container {
    display: inline-flex;
    align-items: center;
    padding: 8px 12px;
    background: #CCCCCC57;
    border-radius: 40px;
    text-transform: capitalize;
}

.indicator {
    margin-left: 8px;
    height: 12px;
    width: 12px;
    background-color: #bbb;
    border-radius: 50%;
    display: block;

    &.open, &.approved {
        background-color: variables.$status-green;
    }

    &.processing, &.for-review, &.queried, &.unapproved {
        background-color: #E32828;
    }
}
