.container {
    display: flex;
    column-gap: 10px;
    position: relative;
    padding: 14px;
    transition: background 0.3s ease;
    cursor: pointer;
    box-shadow: none;

    &:hover {
        background-color: #efefef;
    }
}

.announcementDetails {
    display: flex;
    flex-direction: column;
    row-gap: 2px;

    > h2 {
        margin: auto 0 0 0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        line-height: 20px;
        max-height: 40px;
        color: #111111;
        font-size: 15px;
        font-weight: 600;
    }

    > h3 {
        max-height: 20px;
        line-height: 20px;
        margin: 0 0 auto 0;
        color: #767676;
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 500;
    }
}

.date {
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 14px;
    font-family: inherit;
    font-weight: 400;
    color: #757575;
}

.type {
    text-transform: uppercase;
}
