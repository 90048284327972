@use './variables';
@use './fonts';

html {
    scrollbar-width: auto;
    scrollbar-color: rgba(#951B81, 1) transparent;
}

body {
    min-height: 100%;
    background: #eee;
    font-family: variables.$base-font-family;
    font-size: 16px;
    color: #333;
    overflow-y: auto;
}

.pageContainer {
    position: absolute;
    top: 62px;
    left: 250px;
    width: calc(100% - 250px);
    max-width: 1400px;
    padding: 35px 20px;
}

.fullPageContainer {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
}


/* Scroll bar styling */
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

::-webkit-scrollbar-track {
    background: #E4E4E4;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #951b81;
    border-radius:8px;
    height: 30%;

    &:hover {
        background: #555;
    }
}
