.titleBarContainer {
    display: flex;
    align-items: center;
}

.rightTitleBar {
    margin-left: auto;
}

.title {
    margin: 0px;
    padding: 0px;
    font-size: 24px;
    font-weight: 700;
    color: #111827;
}

.subtitle {
    margin: 8px 0px 0px 0px;
    font-weight: normal;
}

.content {
    display: flex;
    gap: 30px;
}

.topSpacing {
    margin-top: 60px;
}
