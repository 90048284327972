@use 'src/variables';

.primary {
    padding: 9px 17px;
    border: none;
    border-radius: 6px;
    background: variables.$tipro-accent;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
}
